const DataServices = () => import('../views/DataManagement/Services')
const DataService = () => import('../views/DataManagement/Service')
const Brands = () => import('../views/DataManagement/Brands')
const Prospects = () => import('../views/DataManagement/Prospects')
const BodyStyles = () => import('@/views/DataManagement/BodyStyles')
const Years = () => import('../views/DataManagement/Years')
const VehicleModels = () => import('../views/DataManagement/VehicleModels')
const VehicleAttributes = () => import('../views/DataManagement/VehicleAttributes')
const VehicleTrims = () => import('@/views/DataManagement/VehicleTrims')
const DigitalRetailingTools = () => import('@/views/DataManagement/DigitalRetailingTools')
const ScheduleServiceTools = () => import('@/views/DataManagement/ScheduleServiceTools')
const ClientGroups = () => import('../views/DataManagement/ClientGroups')
const ChatTools = () => import('@/views/DataManagement/ChatTools')
const TradeTools = () => import('@/views/DataManagement/TradeTools')
const DealershipTypes = () => import('@/views/DataManagement/DealershipTypes')
const ClientCMS = () => import('@/views/DataManagement/ClientCMS')
const VehicleAttributesReporting = () => import('@/views/DataManagement/VehicleAttributesReporting')
const VehicleAttributeManagement = () => import('@/views/DataManagement/VehicleAttributeManagement')
const ClientMetaTemplate = () => import('@/views/ClientMeta/ClientMetaTemplate')
const GeneratedMetaTemplate = () => import('@/views/ClientMeta/GeneratedMetaTemplate')

const dataManagementRoutes = [
  {
    path: '/data-management',
    name: 'data-management',
    component: DataServices,
    meta: {
      requiresPerm: 'data-management-view',
      requiresAuth: true,
      module: 'data-management'
    },
    redirect: '/data-management/services'
  },
  {
    path: '/data-management/services',
    name: 'data-management.services',
    component: DataServices,
    meta: {
      requiresPerm: 'data-management-view',
      requiresAuth: true,
      module: 'data-management'
    },
    redirect: '/data-management/services/list'
  },
  {
    path: '/data-management/services/list',
    name: 'data-management.services.list',
    component: DataServices,
    meta: {
      requiresPerm: 'data-management-view',
      requiresAuth: true,
      module: 'data-management'
    },
    redirect: '/data-management/services/list/service'
  },
  {
    path: '/data-management/services/list/:category',
    name: 'data-management.services.list.filtered',
    component: DataServices,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/data-management/services/new',
    name: 'data-management.services.new',
    component: DataService,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/data-management/services/:id',
    name: 'data-management.services.edit',
    component: DataService,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/brands',
    name: 'brands',
    component: Brands,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/years',
    name: 'years',
    component: Years,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/vehicle-models',
    name: 'vehicle-models',
    component: VehicleModels,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/vehicle-attributes-reporting',
    name: 'vehicle-attributes-reporting',
    component: VehicleAttributesReporting,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/vehicle-attributes-management',
    name: 'vehicle-attributes-management',
    component: VehicleAttributeManagement,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/body-styles',
    name: 'body-styles',
    component: BodyStyles,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/vehicle-attributes',
    name: 'vehicle-attributes',
    component: VehicleAttributes,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/vehicle-attributes/:brand/:modelYear/:trim',
    name: 'vehicle-attributes.filtered',
    component: VehicleAttributes,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/vehicle-attributes/:brand/:modelYear',
    name: 'vehicle-attributes.edit',
    component: VehicleAttributes,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/vehicle-trims',
    name: 'vehicle-trims',
    component: VehicleTrims,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/vehicle-trims/:brand/:modelYear',
    name: 'vehicle-trims.edit',
    component: VehicleTrims,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/digital-retailing-tools',
    name: 'digital-retailing-tools',
    component: DigitalRetailingTools,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/schedule-service-tools',
    name: 'schedule-service-tools',
    component: ScheduleServiceTools,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/chat-tools',
    name: 'chat-tools',
    component: ChatTools,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/dealership-types',
    name: 'dealership-types',
    component: DealershipTypes,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/client-groups',
    name: 'client-groups',
    component: ClientGroups,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/trade-tools',
    name: 'trade-tools',
    component: TradeTools,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/client-cms',
    name: 'client-cms',
    component: ClientCMS,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/meta-templates',
    name: 'meta-templates',
    component: ClientMetaTemplate,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/generated-meta-templates',
    name: 'generated-meta-templates',
    component: GeneratedMetaTemplate,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  },
  {
    path: '/prospects',
    name: 'prospects',
    component: Prospects,
    meta: {
      requiresAuth: true,
      module: 'data-management'
    }
  }
]

export default dataManagementRoutes
