<template>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card m-5">
        <div class="card-header text-center"><h3>Login</h3></div>
        <div class="card-body">
          <div>
            <inline-message :message="displayMessage" :type="messageType"/>
            <form @submit.prevent="submit">
              <div class="mb-3" :class="{ 'hasError': v$.form.email.$errors.length }">
                <label for="email">E-mail</label>
                <input type="email" id="email" class="form-control" placeholder="user@example.com"
                       v-model="form.email" autocomplete="email">
                <div class="error" v-if="v$.form.email.required.$invalid  && v$.form.email.$dirty">Email is required</div>
                <div class="error" v-if="v$.form.email.email.$invalid && v$.form.email.$dirty">
                  Email is not a properly formatted email address
                </div>
              </div>
              <div class="mb-3" :class="{ 'hasError': v$.form.password.$errors.length }">
                <label for="password">Password</label>
                <input type="password" id="password" class="form-control" v-model="form.password" autocomplete="current-password">
                <div class="error" v-if="v$.form.password.required.$invalid  && v$.form.password.$dirty">Password is required
                </div>
              </div>
<!--              <div class="mb-3">-->
<!--                <div class="col-md-6 offset-md-4">-->
<!--                  <div class="form-check">-->
<!--                    <input class="form-check-input" type="checkbox" name="remember" id="remember" @click="remember">-->
<!--                    <label class="form-check-label" for="remember">-->
<!--                      Remember Me-->
<!--                    </label>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <div class="mb-3 d-flex mb-0">
                <div class="mx-auto">
                  <loading-button type="submit" class="btn btn-primary" :loading="isLoading">
                    <template v-slot:default><i class="fas fa-sign-in-alt"></i></template>
                    <template v-slot:text>&nbsp;Login</template>
                  </loading-button>
                  <router-link :to="{ name: 'auth.forgot-password' }" class="btn btn-primary ms-2">
                    Forgot Your Password?
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'LoginPage',
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      form: {
        email: null,
        password: null,
        remember: false
      },
      isLoading: false,
      displayMessage: '',
      messageType: ''
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  computed: {
    ...mapGetters(['isClientUser', 'isPartnerUser', 'isSubaruCentralRegion'])
  },
  methods: {
    submit () {
      this.resetMessage()
      this.v$.form.$touch()
      this.invalid = this.v$.form.$invalid
      if (this.invalid === false) {
        this.login()
      } else {
        this.showMessage('danger', 'The form has errors, please fix them.')
      }
    },
    login () {
      this.isLoading = true
      const email = this.form.email
      const password = this.form.password
      this.$store.dispatch('login', {
        email,
        password
      })
        .then((response) => {
          this.isLoading = false
          this.$store.dispatch('loginWhiteLabel', {
            email,
            password
          }).then(() => {
            if (this.isSubaruCentralRegion) {
              return this.$router.push({name: 'client-contents'});
            }
            if (response.data.user.user_type === 'client_user') {
              if (typeof response.data.user.id !== 'undefined') {
                this.hasOnboardingClients(response.data.user.id)
              }
            } else if (response.data.user.user_type === 'partner_user') {
              if (typeof response.data.user.id !== 'undefined') {
                this.hasOnboardingClients(response.data.user.id)
              }
            } else {
              this.$router.push('/')
            }
          })
        })
        .catch(err => {
          console.error(err)
          this.isLoading = false
          if (err.response.data && err.response.data.error && err.response.data.error !== 'Unauthorised') {
            this.showMessage('danger', err.response.data.error)
          } else {
            this.showMessage('danger', 'There was an error, unable to sign in with those credentials.')
          }
        })
    },
    hasOnboardingClients (userId) {
      if (this.isClientUser) {
        this.$api.get('/client-users/'+userId+'/onboarding-clients')
            .then(response => {
              this.isLoading = false
              if (response.data) {
                this.$router.push('/onboarding')
              } else {
                window.location.href = 'https://platform.reunionmarketing.com'
              }
            }, (error) => {
              this.isLoading = false
              console.log(error)
            })
      } else if (this.isPartnerUser) {
        this.$api.get('/partner-users/'+userId+'/onboarding-clients')
            .then(response => {
              this.isLoading = false
              if (response.data) {
                this.$router.push('/onboarding')
              } else {
                this.$router.push('/seo-reporting-dashboard/clients')
              }
            }, (error) => {
              this.isLoading = false
              console.log(error)
            })
      } else {
        this.isLoading = false
      }
    },
    showMessage (type, msg) {
      this.displayMessage = msg
      this.messageType = type
    },
    resetMessage () {
      this.displayMessage = this.messageType = ''
    },
    remember: function () {
      if (this.remember_me === true) {
        // do something
      }
    }
  }
}
</script>

<style/>
