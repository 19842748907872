const TechnicalSeoCrawl = () =>
  import('@/views/ServiceTrackers/TechnicalSeoCrawl/TechnicalSeoCrawl.vue')
const OrganicTrafficAudit = () =>
  import('@/views/ServiceTrackers/OrganicTrafficAudit/index.vue')
const ConversionTrackingAudit = () =>
  import('@/views/ServiceTrackers/ConversionTracking/index.vue')
const GbpAudit = () =>
  import('@/views/ServiceTrackers/GbpAudit/index.vue')
const Focuses = () =>
  import('@/views/ServiceTrackers/Focuses/index.vue')
const FocusManagement = () =>
  import('@/views/ServiceTrackers/Focuses/FocusManagement.vue')
const Keywords = () =>
  import('@/views/ServiceTrackers/Keywords/Index.vue')
const Visibility = () =>
  import('@/views/ServiceTrackers/Visibility/Index.vue')
const ThirdPartyVendorsView = () =>
  import('@/views/ServiceTrackers/ThirdPartyVendors/index.vue')

const serviceTracketRoutes = [
  {
    path: '/service-tracker/technical-seo-crawl',
    name: 'service-tracker.technical-seo-crawl',
    component: TechnicalSeoCrawl,
    meta: {
      requiresPerm: 'technical-seo-crawl-view',
      requiresAuth: true,
      module: 'service-tracker'
    }
  },
  {
    path: '/service-tracker/organic-traffic-audit',
    name: 'service-tracker.organic-traffic-audit',
    component: OrganicTrafficAudit,
    meta: {
      requiresPerm: 'technical-seo-crawl-view',
      requiresAuth: true,
      module: 'service-tracker'
    }
  },
  {
    path: '/service-tracker/conversion-tracking-audit',
    name: 'service-tracker.conversion-tracking-audit',
    component: ConversionTrackingAudit,
    meta: {
      requiresPerm: 'technical-seo-crawl-view',
      requiresAuth: true,
      module: 'service-tracker'
    }
  },
  {
    path: '/service-tracker/gbp-audit',
    name: 'service-tracker.gbp-audit',
    component: GbpAudit,
    meta: {
      requiresPerm: 'gbp-audit',
      requiresAuth: true,
      module: 'service-tracker'
    }
  },
  {
    path: '/service-tracker/focuses/:id/',
    name: 'service-tracker.focuses',
    component: Focuses,
    meta: {
      requiresPerm: 'gbp-audit',
      requiresAuth: true,
      module: 'service-tracker'
    }
  },
  {
    path: '/service-tracker/focus-management/',
    name: 'service-tracker.focus-management',
    component: FocusManagement,
    meta: {
      requiresPerm: 'gbp-audit',
      requiresAuth: true,
      module: 'service-tracker'
    }
  },
  {
    path: '/service-tracker/keywords',
    name: 'service-tracker.keywords',
    component: Keywords,
    meta: {
      requiresPerm: 'keywords-service-tracker',
      requiresAuth: true,
      module: 'service-tracker'
    }
  },
  {
    path: '/service-tracker/visibility',
    name: 'service-tracker.visibility',
    component: Visibility,
    meta: {
      requiresPerm: 'search-visibility',
      requiresAuth: true,
      module: 'service-tracker'
    }
  },
  {
    path: '/service-tracker/third-party-vendors',
    name: 'service-tracker.third-party-vendors',
    component: ThirdPartyVendorsView,
    meta: {
      requiresPerm: 'third-party-vendors',
      requiresAuth: true,
      module: 'service-tracker'
    }
  }

]

export default serviceTracketRoutes
