import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'

import Register from '../views/Auth/Register'
import NotFound from '../views/General/NotFound'
import clientInfoCollectionRoutes from './client-info-collection'
import contentDdToolRoutes from './content-dd-tool'
import dataManagementRoutes from './data-manamgement'
import fbDdToolRoutes from './fb-dd-tool'
import gaDdToolRoutes from './ga-dd-tool'
import clientOnboardingRoutes from './client-onboarding-tool'
import servicePackageRoutes from './service-packages'
import specialsManagementRoutes from './specials-management'
import userManagementRoutes from './user-management'
import keyliftReportingRoutes from './keylift-reporting'
import contentManagementRoutes from './content-manamgement'
import serviceTracketRoutes from './service-tracker-tool'
import clientsRoutes from '@/router/clients'
import gbpToolRoutes from './gbp-tool'
import partnersRoutes from './partners'
import prospectsRoutes from './prospects'
import nonAutomatedPlannedContentRoutes from './napc-management'
import AuthLayout from '../layouts/AuthLayout'
import AdminLayout from '../layouts/AdminLayout'
import Home from '../views/Dashboard/Home'
import Login from '../views/Auth/Login'
import ForgotPassword from '../views/Auth/ForgotPassword'
import ResetPassword from '../views/Auth/ResetPassword'

const ClientFeeds = () => import(/* webpackChunkName: "ClientFeeds" */ '@/views/Shared/ClientFeeds/ClientFeeds')
const ClientFeedsList = () => import(/* webpackChunkName: "ClientFeedsList" */ '@/views/Shared/ClientFeeds/ClientFeedsList')
const ClientSettings = () => import(/* webpackChunkName: "ClientSettings" */ '@/views/Shared/ClientFeeds/ClientSettings')
const SeoReportingDashboard = () => import('@/views/SeoReportingDashboard/index.vue')
const SingleClientView = () => import('@/views/SeoInternalDashboard/SingleClientView.vue')
const GroupClientView = () => import('@/views/SeoInternalDashboard/ClientGroupView.vue')
const NewsManagerView = () => import('@/views/News/Index.vue')
const KeyliftExperienceClients = () => import('@/views/KeyliftExperience/Clients/Index.vue')
const KeyliftExperienceProspects = () => import('@/views/KeyliftExperience/Prospects/Index.vue')
const KeyliftExperienceProspectAuth = () => import('@/views/KeyliftExperience/Prospects/ProspectAuth.vue')
const SemrushProfile = () => import('@/views/SemrushProfile/Index.vue')


const routes = [
  {
    path: '/',
    name: 'admin',
    component: AdminLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/client-feeds',
        name: 'client-feeds',
        component: ClientFeeds,
        meta: {
          requiresAuth: true,
          module: 'gadd'
        }
      },
      {
        path: '/fb-client-feeds',
        name: 'fb-client-feeds',
        component: ClientFeeds,
        meta: {
          requiresAuth: true,
          module: 'fb-dd-tool'
        }
      },
      {
        path: '/client-feeds/a2',
        name: 'client-feeds.a2',
        component: ClientFeedsList,
        meta: {
          requiresAuth: true,
          module: 'client-feeds.a2'
        }
      },
      {
        path: '/client-feeds/s3',
        name: 'client-feeds.s3',
        component: ClientFeedsList,
        meta: {
          requiresAuth: true,
          module: 'client-feeds.s3'
        }
      },
      {
        path: '/fb-client-feeds/a2',
        name: 'fb-client-feeds.a2',
        component: ClientFeedsList,
        meta: {
          requiresAuth: true,
          module: 'fb-client-feeds.a2'
        }
      },
      {
        path: '/fb-client-feeds/s3',
        name: 'fb-client-feeds.s3',
        component: ClientFeedsList,
        meta: {
          requiresAuth: true,
          module: 'fb-client-feeds.s3'
        }
      },
      {
        path: '/client-settings',
        name: 'client-settings',
        component: ClientSettings,
        meta: {
          requiresAuth: true,
          module: 'client-settings'
        }
      },
      {
        path: '/seo-manager-report',
        name: 'seo-manager-report',
        component: SeoReportingDashboard,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/internal-dashboard/clients',
        name: 'select-client-view-select',
        meta: {
          requiresPerm: 'seo-internal-dashboard',
          requiresAuth: true,
          module: 'seo-internal-dashboard'
        },
        component: SingleClientView,
        children: [
          {
            path: ':id',
            name: 'single-client-view',
            component: SingleClientView,
            meta: {
              requiresPerm: 'seo-internal-dashboard',
              requiresAuth: true,
              module: 'seo-internal-dashboard'
            }
          }
        ]
      },
      {
        path: '/seo-reporting-dashboard/clients',
        name: 'seo-reporting-dashboard',
        meta: {
          requiresAuth: true
        },
        component: KeyliftExperienceClients,
        children: [
          {
            path: ':id',
            name: 'seo-reporting-dashboard-clients-detail',
            component: KeyliftExperienceClients,
            meta: {
              requiresAuth: true,
            }
          }
        ]
      },
      {
        path: '/seo-reporting-dashboard/prospects',
        name: 'seo-reporting-dashboard-prospects',
        meta: {
          requiresAuth: true
        },
        component: KeyliftExperienceProspects,
        children: [
          {
            path: ':id',
            name: 'seo-reporting-dashboard-prospects-detail',
            component: KeyliftExperienceProspects,
            meta: {
              requiresAuth: true,
            }
          }
        ]
      },
      {
        path: '/internal-dashboard/client-group/:clientGroupId',
        name: 'client-group-view',
        component: GroupClientView,
        meta: {
          requiresPerm: 'seo-reporting-dashboard',
          requiresAuth: true,
          module: 'seo-reporting-dashboard'
        }
      },
      {
        path: '/news-manager',
        name: 'news-manager',
        component: NewsManagerView,
        meta: {
          requiresPerm: 'news-manager',
          requiresAuth: true,
          module: 'news-manager'
        }
      },
      {
        path: '/semrush-profile',
        name: 'semrush-profile',
        component: SemrushProfile,
        children: [
          {
            path: ':id',
            name: 'semrush-profile-detail',
            component: SemrushProfile,
            meta: {
              requiresAuth: true,
            }
          }
        ],
        meta: {
          requiresAuth: true,
        }
      },
      ...clientInfoCollectionRoutes,
      ...clientOnboardingRoutes,
      ...contentDdToolRoutes,
      ...fbDdToolRoutes,
      ...gaDdToolRoutes,
      ...specialsManagementRoutes,
      ...dataManagementRoutes,
      ...servicePackageRoutes,
      ...userManagementRoutes,
      ...clientsRoutes,
      ...partnersRoutes,
      ...prospectsRoutes,
      ...gbpToolRoutes,
      ...contentManagementRoutes,
      ...serviceTracketRoutes,
      ...nonAutomatedPlannedContentRoutes,
      ...keyliftReportingRoutes
    ]
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthLayout,
    redirect: '/auth/login',
    children: [
      {
        path: '/auth/login',
        name: 'auth.login',
        component: Login
      },
      {
        path: '/auth/reset-password',
        name: 'auth.reset-password',
        component: ResetPassword
      },
      {
        path: '/auth/forgot-password',
        name: 'auth.forgot-password',
        component: ForgotPassword
      },
      {
        path: '/auth/register/:token',
        name: 'auth.register',
        component: Register
      },
      {
        path: '/keylift-experience/prospect/auth',
        name: 'keylift-experience-prospect-auth',
        component: KeyliftExperienceProspectAuth,
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    document.getElementById('app').scrollIntoView()
    return null
  }
  // recommended solution in the docs - does not work reliably!
  // https://router.vuejs.org/guide/advanced/scroll-behavior.html
  // scrollBehavior (to, from, savedPosition) {
  //   return {
  //     top: 0
  //   }
  // }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      const subaruClientAllowedRoutes = [
        'client-contents',
        'content.fixed-ops-page',
        'content.client-contents-page',
        'napc-page-content',
        'content.mcp-mob-page',
      ]

      if (store.getters.isClientUser) {
        // client users - hard coding their allowed pages for now
        const allowed = [
          'onboarding-progress-board',
          'onboarding-client-forms',
          'client-users.profile',
          'seo-reporting-dashboard',
          'seo-reporting-dashboard-clients-detail',
        ];

        if (store.getters.isSubaruCentralRegion) {
          allowed.push(...subaruClientAllowedRoutes);
        }

        if (to.matched.some(record => record.name)) {
          if (allowed.includes(to.name)) {
            next() // continue
            return
          }
        } else {
          // redirect to onboarding, if logged in and trying to go to auth routes
          // or it's not a page they are supposed to go to, redirect to onboarding
          next('/onboarding')
          return
        }
      } else if (store.getters.isPartnerUser) {
        // partners users - hard coding their allowed pages for now
        const allowed = [
          'onboarding-progress-board',
          'onboarding-client-forms',
          'partner-users.profile',
          'seo-reporting-dashboard',
          'seo-reporting-dashboard-clients-detail'
        ];

        if (store.getters.isSubaruCentralRegion) {
          allowed.push(...subaruClientAllowedRoutes);
        }
        if (to.matched.some(record => record.name)) {
          if (allowed.includes(to.name)) {
            next() // continue
            return
          }
        } else {
          // redirect to onboarding, if logged in and trying to go to auth routes
          // or it's not a page they are supposed to go to, redirect to onboarding
          next('/onboarding')
          return
        }
      } else {
        if (to.matched.some(record => record.meta.requiresRole)) { // check for role
          if (store.getters.hasRole(to.meta.requiresRole)) {
            next() // continue
            return
          }
          next('/home') // redirect to home
        } else if (to.matched.some(record => record.meta.requiresPerm)) { // check for permission
          if (store.getters.hasPerm(to.meta.requiresPerm)) {
            next() // continue
            return
          }
          next('/home') // redirect to home
          next()
          return
        }
        next() // continue
        return
      }
    }
    next('/auth/login') // redirect to login
  } else {
    if (store.getters.isLoggedIn && to.name?.search('auth') !== -1) {
      if (store.getters.isClientUser || store.getters.isPartnerUser) {
        next('/onboarding')
      } else if (store.getters.isPartnerUser) {
        next('/onboarding')
      } else {
        next('/')
      }
    }
    next() // continue
  }
})

export default router
