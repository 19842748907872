import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from '../plugins/axios'
import axiosWhiteLabel from '../plugins/axios-white-label'
import _ from 'lodash'
import userData from './user-data'

export default createStore({
  modules: {
    userData: userData
  },
  state: {
    status: '',
    token: '',
    user: {},
    userRoles: [],
    userPerms: [],
    success: '',
    error: '',
    warning: '',
    info: '',
    menus: [],
    sidebarMenu: {},
    activeSidebar: null
  },
  mutations: {
    status_loading (state) {
      state.status = 'loading'
    },
    status_error (state) {
      state.status = 'error'
    },
    status_success (state) {
      state.status = 'success'
    },
    auth_success (state, payload) {
      state.status = 'success'
      state.token = payload.token
      state.user = payload.user
      state.userRoles = _.keys(payload.user.roles_to_perms)
      state.userPerms = _.uniq(_.flatMap(payload.user.roles_to_perms))
      axios.defaults.headers.common.Authorization = 'Bearer ' + payload.token
    },
    profile_success (state, payload) {
      state.status = 'success'
      state.user = payload.user
      state.userRoles = _.keys(payload.user.roles_to_perms)
      state.userPerms = _.uniq(_.flatMap(payload.user.roles_to_perms))
    },
    logout (state) {
      state.token = ''
      state.user = {}
      state.userRoles = state.userPerms = []
      state.menus = null
      state.sidebarMenu = null
      delete axios.defaults.headers.common.Authorization
    },
    initialise (state) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + state.token
    },
    app_messages (state, payload) {
      state.error = payload.error || ''
      state.success = payload.success || ''
      state.warning = payload.warning || ''
      state.info = payload.info || ''
      setTimeout(() => {
        this.commit('reset_messages')
      }, 3000)
    },
    reset_messages (state) {
      state.error = state.success = state.warning = state.info = ''
    },
    set_menus (state, payload) {
      state.menus = payload
    },
    set_sidebar_menu (state, payload) {
      state.sidebarMenu = {
        ...state.sidebarMenu,
        ...payload
      }
    },
    set_active_sidebar (state, payload) {
      state.activeSidebar = payload
    }
  },
  actions: {
    login ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('status_loading')
        axios({
          url: '/login',
          data: user,
          method: 'POST'
        })
          .then(resp => {
            commit('app_messages', { success: 'Login Successful!.' })
            commit('auth_success', {
              token: resp.data.token,
              user: resp.data.user
            })
            resolve(resp)
          })
          .catch(err => {
            commit('status_error')
            commit('app_messages', { error: 'Login Failed!.' })
            reject(err)
          })
      })
    },
    loginWhiteLabel ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('status_loading')
        axiosWhiteLabel({
          url: '/sanctum/csrf-cookie'
        })
        axiosWhiteLabel({
          url: '/api/login',
          data: user,
          method: 'POST'
        }).then(resp => {
          resolve(resp)
        })
          .catch(err => {
            commit('status_error')
            reject(err)
          })
      })
    },
    reset ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('status_loading')
        axios({
          url: '/reset',
          data: payload,
          method: 'POST'
        })
          .then(resp => {
            commit('auth_success', {
              token: resp.data.token,
              user: resp.data.user
            })
            resolve(resp)
          })
          .catch(err => {
            commit('status_error')
            reject(err)
          })
      })
    },
    register ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('status_loading')
        axios({
          url: '/register',
          data: payload,
          method: 'POST'
        })
          .then(resp => {
            commit('auth_success', {
              token: resp.data.token,
              user: resp.data.user
            })
            resolve(resp)
          })
          .catch(err => {
            commit('status_error')
            reject(err)
          })
      })
    },
    profile ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('status_loading')
        axios({
          url: '/profile',
          data: payload,
          method: 'PUT'
        })
          .then(resp => {
            const user = resp.data
            commit('profile_success', { user: user })
            resolve(resp)
          })
          .catch(err => {
            commit('status_error')
            reject(err)
          })
      })
    },
    updateProfile ({ commit }, payload) {
      return new Promise((resolve) => {
        commit('status_loading')
        commit('profile_success', { user: payload })
        resolve()
      })
    },
    logout ({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/logout',
          method: 'GET'
        })
          .then(resp => {
            commit('logout')
            resolve(resp)
          })
          .catch(err => {
            commit('status_error')
            commit('logout')
            reject(err)
          })
      })
    },
    noAuthLogout ({ commit }) {
      return new Promise((resolve) => {
        commit('logout')
        commit('app_messages', { error: 'Unauthenticated! Please Log In' })
        resolve()
      })
    },
    setMenus ({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/dynamic-menus/menus',
          method: 'GET'
        })
          .then(res => {
            commit('set_menus', res.data)
            resolve(res)
          })
          .catch(err => {
            commit('status_error')
            commit('logout')
            reject(err)
          })
      })
    },
    setSidebarMenu ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/dynamic-menus/menus/${payload}/items?show_deleted=0`,
          method: 'GET'
        })
          .then(res => {
            commit('set_sidebar_menu', { [payload]: res.data })
            resolve(res)
          })
          .catch(err => {
            commit('status_error')
            commit('logout')
            reject(err)
          })
      })
    },
    setActiveSidebar ({ commit }, payload) {
      return new Promise((resolve) => {
        commit('set_active_sidebar', payload)
        resolve(payload)
      })
    }
  },
  getters: {
    apiToken: state => state.token,
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    currentUser: state => state.user,
    appError: state => state.error,
    appSuccess: state => state.success,
    appWarning: state => state.warning,
    appInfo: state => state.info,
    userRoles: state => state.userRoles,
    userPerms: state => state.userPerms,
    hasRole: state => role => state.userRoles.includes(role) || state.userRoles.includes('super-admin'),
    hasPerm: state => perm => state.userPerms.includes(perm) || state.userRoles.includes('super-admin'),
    isClientUser: state => state.user.user_type === 'client_user',
    isReunionUser: state => state.user.user_type === 'user',
    isPartnerUser: state => state.user.user_type === 'partner_user',
    menus: state => state.menus,
    sidebarMenu: state => state.sidebarMenu,
    activeSidebar: state => state.activeSidebar,
    isSubaruCentralRegion(state) {
      const {user: {client_management_groups, partner}} = state;

      if (partner && partner.name === 'Shift Digital - Subaru') {
        return true;
      }

      return client_management_groups ? Boolean(client_management_groups.find(group => group.slug === 'subaru_central_region')) : false;
    }
  },
  plugins: [createPersistedState()]
})
